import { memo, useState } from 'react';
import Script from 'next/script';

export const ConvertExperiencesWidget = memo(() => {
  const [hasScriptError, setHasScriptError] = useState(false);

  if (hasScriptError) {
    return null;
  }

  return (
    <>
      <Script
        id="convert-experience"
        strategy="afterInteractive"
        src="//cdn-4.convertexperiments.com/js/1004973-100412012.js"
        async
        type="text/javascript"
        onError={() => setHasScriptError(true)}
      />
      <Script id="convert-body-hide-prevention" strategy="beforeInteractive">
        {`(function(){` +
          `var _conv_prevent_bodyhide = true;` +
          `var hideTimeout = 500;` +
          `var cssToHide = "body {visibility: hidden !important;}",` +
          `headElement = document.head || document.getElementsByTagName("headElement")[0],` +
          `styleElement = document.createElement("style");` +
          `headElement.appendChild(styleElement);` +
          `styleElement.type = "text/css";` +
          `styleElement.id = "convert_hide_body";` +
          `if (styleElement.styleSheet){
          styleElement.styleSheet.cssText = cssToHide;
          } else {
          styleElement.appendChild(document.createTextNode(cssToHide));
          }` +
          `setTimeout(function() {var c_h = document.getElementById("convert_hide_body");` +
          `if(c_h) c_h.outerHTML = "";}, hideTimeout)})();`}
      </Script>
    </>
  );
});
